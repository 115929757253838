import { useState } from 'react';
import './App.css';
import ml2 from './assets/ml-2.png';

function App() {
  const [currentPage, setCurrentPage] = useState('hem');
  return (
    <div style={{textAlign: 'center'}}>
      <div style={{
        display: 'flex', 
        position: 'fixed', 
        justifyContent: 'center', 
        width: '100%', 
        gap: '20px', // adds space between menu items
        left: 0, 
        cursor: 'pointer',
        top: 0,
      }}>
        <div style={{ fontWeight: 900, borderTop: currentPage === 'hem' ? '4px solid steelblue' : '4px solid #CBE4F9', backgroundColor: '#CBE4F9', borderBottomRightRadius: 4, borderBottomLeftRadius: 8, padding: 8, boxShadow: '4px 4px rgba(0,0,0,0.10)'}} onClick={() => {
          const hem = document.getElementById('hem')
          hem && hem.scrollIntoView({ behavior: 'smooth' })
          setCurrentPage('hem')
        }}>
          Hem
        </div>
        <div style={{ fontWeight: 900, borderTop: currentPage === 'fragor' ? '4px solid steelblue' : '4px solid #CBE4F9', backgroundColor: '#CBE4F9', borderBottomRightRadius: 4, borderBottomLeftRadius: 8, padding: 8, boxShadow: '4px 4px rgba(0,0,0,0.10)'}} onClick={() => {
          const fragor = document.getElementById('fragor')
          fragor && fragor.scrollIntoView({ behavior: 'smooth' })
          setCurrentPage('fragor')
        }}>
          Frågor och svar
        </div>
      </div>
      <div id="hem" style={{height: '100vh'}}>
          <h1 style={{ paddingTop: '80px' }}>Spara datumet</h1>
          <h3>Lina & Marcus gifter sig den 2:a augusti 2025 i Göteborg</h3>
          <h4>Avvakta inbjudan</h4>
          <div style={{ paddingRight: '16px', paddingLeft: '16px', maxHeight: '40vh' }}>
            <img style={{
    maxWidth: '100%', 
    height: 'auto', 
    maxHeight: '60vh'}} src={ml2} alt='spårvagn'/>
          </div>
      </div>
      <div id="fragor" style={{height: '100vh', textAlign: 'left'}}>
          <div style={{
            width: 'fit-content',
            margin: '0 auto'
          }}>
            <h1 style={{ paddingTop: '80px', textAlign: 'center' }}>Frågor och svar</h1>
            <div style={{ backgroundColor: 'whitesmoke', borderRadius: 8, maxWidth: 600, padding: 16, marginLeft: 16, marginRight: 16}}>
              <h4>F: Var ska festen hållas?</h4>
              <p>S: På Överås Slott, danska vägen 20 i Göteborg.</p>
              <h4>F: Har ni tips på hotell?</h4>
              <p>S: Det finns flera hotell runt Korsvägen, till exempel Gothia Towers eller Hotell Onyxen. 5:ans Bed & Breakfast är närmast. Hör av er om ni vill ha fler tips!</p>
              <h4>F: Får barn vara med?</h4>
              <p>S: Vi tycker om era barn, men just den här dagen vill vi fira i vuxet sällskap. Bebisar och barn som ammar får självklart vara med.</p>
            </div>
          </div>
      </div>
    </div>
  );
}

export default App;


// F: Var ska festen hållas?
// S: På Överås Slott, danska vägen 20 i Göteborg.


// F: Har ni tips på hotell?
// S: Det finns flera hotell runt Korsvägen, till exempel Gothia Towers eller Hotell Onyxen. 5:ans Bed & Breakfast är närmast. Hör av er om ni vill ha fler tips!

// F: Får barn vara med?
// S: Vi tycker om era barn, men just den här dagen vill vi fira i vuxet sällskap. Bebisar och barn som ammar får självklart vara med.
